<template lang="">

    <a-row class="container" type="flex" justify="center">
        <a-col :span="24" :lg="5">
            <img src="@/assets/eva.png" width="250" style="margin-bottom:80px;" v-if="domain == 'sevalube-agent.sequre.id'"/>
            <img src="@/assets/pertamina.png" width="250" style="margin-bottom:80px;" v-if="domain == 'pertamina-web.sequre.id'"/>

            <img src="@/assets/eva.png" width="250" style="margin-bottom:80px;" v-else/>
           <!--  <img src="@/assets/pertamina.png" width="250" style="margin-bottom:80px;"/> -->
        <p style="font-size:16px" class="anu">Login</p>
        <br>
       
        <a-alert :message="error.errorMessage" type="error" v-if="error.loginFailed" />
        <a-input placeholder="Username" v-model:value="login.username" class="auth_input" :status="error.username ? 'error' : ''" ></a-input>
        <small v-if="error.username" class='alert'>Username cannot be empty</small>
        <a-input-password placeholder="Password" v-model:value="login.password" type="password" class="auth_input" :status="error.password ? 'error' : ''" ></a-input-password>
        <small v-if="error.password" class='alert'>Password cannot be empty</small>
        <div class="sized_box"></div>
        <a-button block class="login_button" :loading=login.isLoading @click=prosesLogin >{{(login.isLoading == false)?"Login":"Processing..."}}</a-button>

        </a-col>

    </a-row>
   
   
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { ConfigProvider } from 'ant-design-vue';
//import axios from 'axios';
import axios from "./../../axios/axios"
export default {
    data() {
        return {
            domain: "",
            error: {
                username: false,
                password: false,
                loginFailed: false,
                errorMessage: ''
            },
            login: {
                username: "",
                password: "",
                isLoading: false
            }
        }
    },
    created() {
        this.domain = window.location.host;
    },
    methods: {
        async prosesLogin() {

            this.error.username = false
            this.error.password = false
            if (this.login.username == "") {
                this.error.username = true
            }
            if (this.login.password == "") {
                this.error.password = true
            }
            if (this.error.username == true || this.error.password == true) {
                return false
            }
            this.login.isLoading = true;
            this.error.loginFailed = false;
            let self = this
            let url = `/api/auth/login`;
            try {
                let response = await axios.post(url, this.login)
                this.login.isLoading = false;
                console.log(response.data.data.token);
                let data = {
                    isLogin: true,
                    token:response.data.data.token
                }
                self.updateAuth(data);
                self.login.isLoading = false
                self.$router.push({ path: '/' })
                
            } catch (error) {
               
                this.login.isLoading = false;
                this.error.loginFailed = true;
                this.error.errorMessage = error.response.data.message;
            }

        },
        ...mapActions(['updateStatusLogin', 'updateAuth'])
    },
    computed: {
        // ...mapState(['login'])
    },
    mounted: function () {
        console.log(process.env.VUE_APP_NAME);
        console.log('login init');

    }
}

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

.anu {
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
}

.container {
    display: flex;
    align-items: center;
    height: 90vh;
    margin: 30px;
    margin-top: 0px;
    overflow: none;
}

.auth_input {
    height: 44px;
    border-radius: 4px;
    border: 1px solid #BDBDBD;
    margin-top: 10px;
}

.login_button {
    font-family: "Poppins", sans-serif;
    background-color: #387C44 !important;
    color: white;
    border-color: #387C44 !important;
    border-radius: 4px;
    height: 41px;
}

/* .login_button:active,
:visited,
:hover {
    color: white;
} */

.sized_box {
    height: 17vh;
}

.alert {
    color: red;
    text-align: left !important;
}
.ant-btn-default:not(:disabled):hover{
    color: white !important;
    border-color:white;
}
</style>